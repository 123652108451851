<template>
  <div class="multiple">
    <!-- {{value}} -->
    <div class="item" v-for="(item,i) in values" :key="i">
      <input-code v-model="values[i]" :auto-crc="autoCrc" :is-feedback="isFeedback"
        :is-custom="isCustom" :brand-data="brandData"></input-code>
    </div>
    
    <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="addItem()"></el-button>
    <el-button type="primary" icon="el-icon-minus" circle size="mini" @click="delItem()"></el-button>
  </div>
</template>

<script>
  import inputCode from '@/components/input-code16'
  export default {
    components: {
      inputCode
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      autoCrc: {
        type: Boolean,
        default: false
      },
      isCustom: {
        type: Boolean,
        default: true
      },
      brandData: {
        type: Object
      },
      isFeedback: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        values: this.value.split(';')
      }
    },
    watch: {
      value: function(v) {
        if (!this.value) { this.value = '' }
        this.values = this.value.split(';')
      },
      values: function(v) {
        this.$emit('input', this.values.join(';'))
      }
    },
    methods: {
      addItem() {
        this.values.push('')
      },
      delItem() {
        this.values.splice(-1, 1)
        this.$emit('input', this.values.join(';'))
      }
    }
  }
</script>
<style lang="scss" scoped>
.multiple{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .item{
    margin-right: 10px;
  }
}
</style>
